<template>
    <ion-page>
        <ion-content color="primary" :fullscreen="true">

            <div class="auth-content">
                <div class="auth">
                    <ion-img class="logo" src="https://cms.app.thb.torben.wtf/assets/9e9543b8-95f8-44a7-87ba-7199db322dff"></ion-img>
                    <h2>{{$t('auth.logout')}}</h2>
                    <p>{{$t('auth.logout_text')}}</p>

                    <ion-button color="light" @click="toLogin()">
                        {{ $t('auth.logout_login') }}
                    </ion-button>

                    <ion-button color="dark" @click="toTutorial()">
                        {{ $t('auth.tutorial') }}
                    </ion-button>
                </div>
            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="js">
    import { IonPage, IonToolbar, IonTitle, IonContent, IonLabel, IonButton, IonImg } from '@ionic/vue';
    import {app, directus} from '../main'

    export default {
        name: 'Logout',
        components: { IonToolbar, IonTitle, IonPage, IonContent, IonLabel, IonButton, IonImg },
        methods: {
            toLogin () {
                this.$router.push({name:'login'})
            },
            toTutorial () {
                this.$router.push({name:'tutorial'})
            }
        }
    }
</script>

<style scoped>
    .auth {
        height: 100%;
        margin: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .logo {
        width: 50%;
        max-width: 250px;
    }
</style>
